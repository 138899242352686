export default class TranscodeInterpolate {
  /**
   * @param {string} message
   * @param {object} values
   * @param {Array} boundaryIdentifiers
   */
  interpolate(message, values, boundaryIdentifiers = ['##', '##']) {
    if (!values) {
      return;
    }
    const interpolationKeys = Object.keys(values);
    if (typeof message === 'undefined' || interpolationKeys.length === 0) {
      return message;
    }
    interpolationKeys.forEach((key) => {
      // create case-insensitive regex
      const reg = new RegExp(`${boundaryIdentifiers[0]}${key}${boundaryIdentifiers[1]}`, 'gi');
      message = message?.replace(reg, values[key]);
    });
    return message;
  }

  toJSON() {
    return { ...this };
  }
}
